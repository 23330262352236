import logo from "./logo.svg";
import "./App.css";

(async function () {
  const response = await fetch(
    "https://abrebion-react-app-assets.s3.amazonaws.com/coffee.jpg"
  );
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  const image = new Image();
  image.src = url;
  document.getElementById("placeholder").appendChild(image);
})();

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <p>Image loaded from other s3 bucket to demonstrate CORS</p>
        <div id="placeholder"></div>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
